
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        



























































.breadcrumb {
  font-size: 1.4rem;
  padding-right: 50%;
  margin-block: 0;
  padding-block: 50px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.submit {
  @include loading($c-orange);

  margin-left: auto;
}
